import { createRouter, createWebHistory } from 'vue-router'

// if (localStorage.getItem("langdata") == null) {
//     localStorage.setItem('langdata', "en")
//     // localStorage.setItem('firstDayOfWeek', 1)
// }

var comptLang = navigator.language || navigator.userLanguage;
var userLang = comptLang.split("-");
loadLocaleMessages();

const langdatakey = localStorage.getItem('langdata');
// console.log("The language is: " + userLang[0] + " " + comptLang);

// console.log(localStorage.getItem('langdata'));



function loadLocaleMessages(){
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const getUserLan = "./"+userLang[0]+".json";
    const messages = {}
    let varmi = false
    locales.keys().forEach(key => {
        if (localStorage.getItem("langdata") == null) {
            if(getUserLan === key)
            {
                varmi = true
            }
        }
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    if (localStorage.getItem("langdata") == null) {
        if(varmi == true)
        {
            localStorage.setItem('langdata', userLang[0])
        }else{
            localStorage.setItem('langdata', "en");
        }
    }

    return messages
}

const routes = [
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/application/Test.vue'),
    },
    {
        path: '',
        redirect : { name : 'home' , params:{ lang: localStorage.getItem('langdata')} },
    },
    {
        path: '/:lang',
        // path: '/en',
        name: 'home',
        component: () => import('../views/frontend/Home.vue'),
        meta: {
            requiresAuth: false,
            title: loadLocaleMessages()[langdatakey].pagedetail.home.title,
            description: loadLocaleMessages()[langdatakey].pagedetail.home.description,
            keywords: loadLocaleMessages()[langdatakey].pagedetail.home.keywords,
        }
    },
    {
        path: '/'+localStorage.getItem('langdata')+'/:stats/:id',
        // path: '/en',
        name: 'statsfront',
        component: () => import('../views/frontend/Stats.vue'),
        meta: {
            requiresAuth: false,
            title: loadLocaleMessages()[langdatakey].pagedetail.home.title,
            description: loadLocaleMessages()[langdatakey].pagedetail.home.description,
            keywords: loadLocaleMessages()[langdatakey].pagedetail.home.keywords,
        }
    },
    {
        name: 'register',
        path: '/'+localStorage.getItem('langdata')+'/register',
        component: () => import('../views/frontend/Register.vue'),
    },
    {
        name: 'login',
        path: '/'+localStorage.getItem('langdata')+'/login',
        component: () => import('../views/frontend/Login.vue'),
    },
    {
      // catch all route
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: ()=> import('../views/NotFound.vue'),

    }

]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  router.beforeEach((to, from, next) => {
    //   console.log("herzaman çalışıyor");
      document.title = to.meta.title
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',to.meta.description)
      document.getElementsByTagName('meta').namedItem('keywords').setAttribute('content',to.meta.keywords)

      if (to.meta.requiresAuth) {
          if (localStorage.getItem('token')) {
              next()
          } else {
              next({
                  path: '/'+localStorage.getItem('langdata')+'/login',
                  query: { redirect: to.fullPath }
              })
          }
      }
      next()
  })
  export default router
