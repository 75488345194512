require('./bootstrap');


import { createApp } from 'vue'

import App from './views/App.vue'
import router from './router'
import axios from 'axios';




axios.defaults.baseURL = 'https://shrted.com/api/';
// axios.defaults.baseURL = 'http://shrted.localhost/api/';
// axios.defaults.baseURL = 'http://192.168.1.3/api/';
// axios.defaults.baseURL = 'http://shrted.localhost/api/';
// axios.defaults.withCredentials = true;

var comptLang = navigator.language || navigator.userLanguage;
var userLang = comptLang.split("-");
// console.log("The language is: " + userLang[0] + " " + comptLang);
// console.log(localStorage.getItem('langdata'));

// if (localStorage.getItem("langdata") == null) {
//     localStorage.setItem('langdata', "en")
//     // localStorage.setItem('firstDayOfWeek', 1)
// }
// console.log(loadLocaleMessages());

// console.log(localStorage.getItem('langdata'));
const app = createApp(App)

import { createI18n } from 'vue-i18n/index'

function loadLocaleMessages(){
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const getUserLan = "./"+userLang[0]+".json";
    const messages = {}
    let varmi = false
    locales.keys().forEach(key => {
        if (localStorage.getItem("langdata") == null) {
            if(getUserLan === key)
            {
                varmi = true
            }
        }
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    if (localStorage.getItem("langdata") == null) {
        if(varmi == true)
        {
            localStorage.setItem('langdata', userLang[0])
        }else{
            localStorage.setItem('langdata', "en");
        }
    }

    return messages
}



// Create VueI18n instance with options
const i18n = createI18n({
    locale: localStorage.getItem('langdata'), // set locale
    // legacy: false, // enable legacy mode
    // globalInjection: true,
    localeDir: 'locales',
    messages: loadLocaleMessages()
})



app.use(i18n)
app.use(router)
app.mount('#shrted')
